<span
  joyrideStep="dummy-templates-step"
  [prevTemplate]="prevButton"
  [nextTemplate]="nextButton"
  [doneTemplate]="doneButton"
  [counterTemplate]="counter"
></span>
<ng-template #prevButton>
  <button mat-flat-button color="accent">{{ messages?.previous }}</button>
</ng-template>
<ng-template #nextButton>
  <button mat-raised-button color="accent">{{ messages?.next }}</button>
</ng-template>
<ng-template #doneButton>
  <button mat-raised-button color="accent">{{ messages?.complete }}</button>
</ng-template>
<ng-template #counter let-step="step" let-total="total">
  <etn-tour-counter [total]="total" [step]="step"></etn-tour-counter>
</ng-template>

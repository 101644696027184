import {
  EnvironmentProviders,
  importProvidersFrom,
  makeEnvironmentProviders,
} from '@angular/core';
import { JoyrideModule } from 'ngx-joyride';

import { TourService } from './tour.service';

export function provideTour(): EnvironmentProviders {
  const providers = [TourService, importProvidersFrom(JoyrideModule.forRoot())];
  return makeEnvironmentProviders(providers);
}

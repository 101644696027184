import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { Nil } from '@model';
import { JoyrideModule } from 'ngx-joyride';

import { TourStep } from './tour.types';

@Component({
  selector: 'etn-tour',
  templateUrl: './tour.component.html',
  styleUrls: ['./tour.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, MatButtonModule, JoyrideModule],
})
export class TourComponent {
  @Input() public step: TourStep | Nil;
}

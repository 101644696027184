import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
} from '@angular/core';
import { Nil } from '@model';
import { isNil } from 'lodash-es';

@Component({
  selector: 'etn-tour-counter',
  templateUrl: './tour-counter.component.html',
  styleUrls: ['./tour-counter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule],
})
export class TourCounterComponent implements OnChanges {
  @Input() public total: number | Nil;
  @Input() public step: number | Nil;

  public steps: boolean[] = [];

  public ngOnChanges(): void {
    if (isNil(this.total) || isNil(this.step)) {
      this.steps = [];
      return;
    }
    this.steps = new Array(this.total).fill(false);
    if (this.steps.length >= this.step) {
      this.steps[this.step - 1] = true;
    }
  }
}

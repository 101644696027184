import { Injectable } from '@angular/core';
import { Nil } from '@model';
import { JoyrideService } from 'ngx-joyride';
import { Subscription } from 'rxjs';

import { TourStep } from './tour.types';

@Injectable()
export class TourService {
  public constructor(private readonly joyrideService: JoyrideService) {}

  private sub: Subscription | Nil;

  public initialize(steps: TourStep[], callback?: () => void): void {
    this.sub = this.joyrideService
      .startTour({
        steps: steps.map((step) => {
          return `${step.id}`;
        }),
        logsEnabled: true,
      })
      .subscribe(
        () => {
          // we do nothing
        },
        () => {
          // we do nothing
        },
        () => {
          if (callback) {
            callback();
          }
        },
      );
  }

  public destroy(): void {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }
}
